import React, { useEffect, useState } from 'react'
import MainPage from './pages/main'
import Footer from './components/footer/footer'
import BookingForm from './components/bookingForm/bookingForm'
import { Modal } from 'antd'
import { Route, Routes, useLocation } from 'react-router-dom'
import PolicyPage from './components/policy.js/policy'
import './layout.scss'
import TermsPage from './components/terms/termsPage'
import HomePage from './pages/HomePage'
const Layout = (props) => {

  const location = useLocation();
  const[showFooter,setShowFooter]= useState(false);
  useEffect(() => {
  if (location.pathname === '/vpi') {
    setShowFooter(false);
  } else {
    setShowFooter(true);
  }
  }, [location.pathname]);

  return (
    <div>
      <Routes>
        <Route path='/' element={ <MainPage setBookingFlag={props.setBookingFlag} />} />
        <Route path='/vpi' element={ <HomePage setBookingFlag={props.setBookingFlag}/>} />
        <Route path='/policy' element={ <PolicyPage />} />
        <Route path='/terms-and-condition' element={<TermsPage />} />
        <Route exact path='*' element={ <HomePage setBookingFlag={props.setBookingFlag} />} />
      </Routes>
     
    {!showFooter ? '' : <Footer />}
      {props.bookingFlag &&
        <Modal
        open={true}
        closable={false}
        closeIcon={false}
        // onCancel={()=>props.setBookingFlag(false)}
        width={1200}
        footer={null}
      >
       <BookingForm setBookingFlag={props.setBookingFlag} />
       </Modal>}
    </div>
  )
}

export default Layout