// import './App.css';
import Header from './components/header/header';
import "./assets/scss/style.scss";
import { useEffect, useState } from 'react';
import BookingForm from './components/bookingForm/bookingForm';
import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './layout';
function App() {
  const location=useLocation();
  const [bookingFlag, setBookingFlag] = useState(false);
  const[showHeader,setShowHeader]=useState(false);
    useEffect(() => {
    if (location.pathname.includes('/vpi')) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [location.pathname]);


  return (
    <div className="App">
      {!showHeader ? '' :<div className="nav_box">
				<Header bookingFlag={bookingFlag} setBookingFlag ={setBookingFlag} />
			</div>}
      <Routes>
        <Route path='/' exact={true} element={<Layout bookingFlag={bookingFlag} setBookingFlag={setBookingFlag} />} />
        <Route path='/contactus' exact={true} element={<BookingForm bookingFlag={bookingFlag} setBookingFlag={setBookingFlag} />} />
        <Route path='*' element={<Layout bookingFlag={bookingFlag} setBookingFlag={setBookingFlag} />} />
      </Routes>
    </div>
  );
}

export default App;
